import * as Sentry from "@sentry/react";
import { ErrorProvider } from "context/ErrorHandler";
import SignupCheckout from "pages/SignupCheckout";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { EmailVerificationClaim } from "supertokens-auth-react/recipe/emailverification";
import {
  SessionAuth,
  useSessionContext,
} from "supertokens-auth-react/recipe/session";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import DeletionRequest from "pages/DeletionRequest";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import "./App.css";
import { PreBuiltUIList, SuperTokensConfig } from "./config";
import { ThemeProvider } from "./context/ThemeProvider";
import Layout from "./Layout";
import About from "./pages/About";
import AddToHome from "./pages/AddToHome";
import { AdminDashboard } from "./pages/AdminDashboard";
import AlbumView from "./pages/AlbumView";
import Browse from "./pages/Browse";
import BrowseTag from "./pages/Browse/components/BrowseTag";
import CreatorView from "./pages/CreatorView";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Juiced from "./pages/Juiced";
import Landing from "./pages/Landing";
import Library from "./pages/Library";
import NoPage from "./pages/NoPage";
import OrderConfirmation from "./pages/OrderConfirmation";
import PlaylistView from "./pages/PlaylistView";
import PostRegisterBuyNow from "./pages/PostRegisterBuyNow";
import Profile from "./pages/Profile";
import SupportQuestions from "./pages/SupportQuestions";
import TrackView from "./pages/TrackView";
import UserView from "./pages/UserView";
import { Toaster } from "./primitives";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
});

SuperTokens.init(SuperTokensConfig);

function InvalidClaimHandler(props: React.PropsWithChildren<any>) {
  let sessionContext = useSessionContext();
  if (sessionContext.loading) {
    return null;
  }

  if (
    sessionContext.invalidClaims.some((i) => i.id === EmailVerificationClaim.id)
  ) {
    window.location.assign("/auth/verify-email?rid=emailverification");
  }

  // We show the protected route since all claims validators have
  // passed implying that the user has verified their email.
  return <div>{props.children}</div>;
}

const queryClient = new QueryClient();

function App() {
  return (
    <SuperTokensWrapper>
      <div className="bg-background mt-2">
        <QueryClientProvider client={queryClient}>
          <ErrorProvider>
            <ThemeProvider defaultTheme="dark">
              <Router>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    {/* This shows the login UI on "/auth" route */}
                    {getSuperTokensRoutesForReactRouterDom(
                      require("react-router-dom"),
                      PreBuiltUIList
                    )}
                    <Route
                      path="home"
                      element={
                        /* This protects the "/" route so that it shows
                                    <Home /> only if the user is logged in.
                                    Else it redirects the user to "/auth" */
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <Home />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />

                    {/* Pages */}
                    <Route index element={<Landing />} />
                    <Route path="dive25" element={<Juiced />} />
                    {/* <Route index element={<Juiced />} /> */}
                    <Route path="about" element={<About />} />
                    <Route path="add-to-home" element={<AddToHome />} />
                    <Route
                      path="order-confirmation"
                      element={<OrderConfirmation />}
                    />
                    <Route path="faq" element={<Faq />} />
                    <Route path="browse" element={<Browse />} />
                    <Route path="browse/tag/" element={<BrowseTag />} />
                    <Route
                      path="get-stagedive"
                      element={<PostRegisterBuyNow />}
                    />
                    <Route
                      path="signup/checkout"
                      element={<SignupCheckout />}
                    />
                    <Route
                      path="support-questions"
                      element={<SupportQuestions />}
                    />
                    <Route
                      path="library"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <Library />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="profile/*"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <Profile />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route path="creator/:id" element={<CreatorView />} />
                    <Route
                      path="user/:id"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <UserView />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="playlist/:id"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <PlaylistView />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />

                    <Route
                      path="album/:id"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <AlbumView />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="track/:id"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <TrackView />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="deletion-request"
                      element={
                        <SessionAuth>
                          <InvalidClaimHandler>
                            <DeletionRequest />
                          </InvalidClaimHandler>
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="admin/*"
                      element={
                        <SessionAuth>
                          <AdminDashboard />
                        </SessionAuth>
                      }
                    />

                    {/* Invalid URL */}
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </Router>
              <Toaster />
            </ThemeProvider>
          </ErrorProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </div>
    </SuperTokensWrapper>
  );
}

export default App;
