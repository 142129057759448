export default async function getCroppedImg(imageSrc: string, crop: any) {
  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        // If the context is null, reject the promise
        reject(new Error("Failed to get canvas 2D context"));
        return;
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      // Set canvas dimensions to cropped area size
      canvas.width = crop.width;
      canvas.height = crop.height;

      // Draw the cropped portion of the image onto the canvas
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      // Convert canvas content to a blob
      canvas.toBlob((blob) => {
        if (!blob) {
          // If blob creation failed, reject the promise
          reject(new Error("Canvas is empty"));
          return;
        }

        // Create a file from the blob
        const croppedFile = new File([blob], "croppedImage.jpeg", {
          type: "image/jpeg",
        });
        resolve(croppedFile);
      }, "image/jpeg");
    };

    // Handle image load error
    image.onerror = (error) => reject(error);
  });
}
