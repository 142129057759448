import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import Modal from "../../../../components/Modal";
import getCroppedImg from "./cropImageHelper";

type Props = {
  handleSubmit;
  user;
  open;
  handleToggle;
  isLoading;
  error;
};

export const UserEditProfileModal = ({
  handleSubmit,
  user,
  open,
  handleToggle,
  isLoading,
  error,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<string | undefined>("/profile.webp");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<string | undefined>();
  const [croppedArea, setCroppedArea] = useState<any>(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(user.profileImage ? user.profileImage : "/profile.webp");
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);

    //@ts-ignore
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleProfileImageChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  // function to handle cropping and setting the cropped image
  const handleCrop = async () => {
    if (croppedArea && selectedFile) {
      try {
        const croppedFile = await getCroppedImg(preview as string, croppedArea);

        // cast croppedFile to Blob
        const objectUrl = URL.createObjectURL(croppedFile as Blob);
        setCroppedImage(objectUrl);
      } catch (error) {
        console.error("Error cropping the image", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (croppedImage) {
        URL.revokeObjectURL(croppedImage);
      }
    };
  }, [croppedImage]);

  const bioMaxLength = 480;

  return (
    <Modal open={open} onClose={handleToggle}>
      <h3 className="font-bold text-lg">Edit Profile</h3>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="mt-3">
          <label htmlFor="profileImageFile">User Profile Image</label>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="fileInput"
            name="profileImageFile"
            onChange={handleProfileImageChange}
          />
          <label htmlFor="fileInput" className="cursor-pointer block">
            <div className="h-20 w-20 rounded-full overflow-hidden border border-gray-400 flex items-center justify-center hover:bg-gray-200">
              <img
                src={croppedImage ? croppedImage : preview}
                alt="User Profile"
                className="h-full w-full object-cover"
              />
            </div>
          </label>
          <small>Size limit: 5mb</small>
        </div>

        {/* Cropping area */}
        {selectedFile && (
          <div>
            <div className="relative h-64 w-full mt-4">
              <Cropper
                image={preview}
                crop={crop}
                zoom={zoom}
                aspect={1} // can adjust the aspect ratio
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="button"
                onClick={handleCrop}
                className="btn normal-case transition duration-300 ease-in-out hover:bg-neutral-700"
              >
                Crop Image
              </button>
            </div>
          </div>
        )}
        <div className="mt-3">
          <label className="mb-4 pb-4 font-bold" htmlFor="displayName">
            Display Name
          </label>
          <input
            className="bg-black shadow appearance-none border rounded w-full mt-2 p-3 text-white focus:outline-none focus:shadow-outline"
            placeholder=""
            name="displayName"
            defaultValue={user.displayName === null ? "" : user.displayName}
          />
        </div>

        <div className="mt-3">
          <label className="mb-4 pb-4 font-bold" htmlFor="bio">
            Bio
          </label>
          <textarea
            className="bg-black shadow appearance-none border rounded w-full mt-2 p-3 text-white focus:outline-none focus:shadow-outline"
            placeholder=""
            name="bio"
            defaultValue={user.bio === null ? "" : user.bio}
            maxLength={bioMaxLength}
          />
        </div>

        <div className="modal-action">
          <button
            className="btn normal-case transition duration-300 ease-in-out hover:bg-neutral-700"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error}</span>
        </div>
      )}
    </Modal>
  );
};
